import { inputAnatomy } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
  defineStyleConfig,
} from "@chakra-ui/react";

export const INPUT_ERROR_STYLES = {
  borderRadius: "lg",
  borderColor: "accent.red",
  boxShadow: "input.error",
  _focus: {
    backgroundColor: "background.primary",
  },
};

const {
  definePartsStyle: defineInputPartsStyle,
  defineMultiStyleConfig: defineInputStyleConfig,
} = createMultiStyleConfigHelpers(inputAnatomy.keys);

export const Input = defineInputStyleConfig({
  baseStyle: defineInputPartsStyle({
    field: {
      padding: "8px",
      fontSize: "14px",
      lineHeight: "16px",
      width: "100%",
      borderWidth: "1px",
      borderColor: "border.secondary",
      boxShadow: "levelInset1",
      transition: "box-shadow 50ms ease-out",
      _hover: {
        cursor: "pointer",
      },
      _invalid: {
        ...INPUT_ERROR_STYLES,
      },
    },

    addon: {
      borderWidth: "1px",
      backgroundColor: "background.primary",
      borderColor: "border.primary",
      borderRadius: "full",
    },
  }),
  defaultProps: {
    variant: "default",
    size: "sm",
  },
  variants: {
    default: defineInputPartsStyle({
      field: {
        borderRadius: "lg",
        _focus: {
          backgroundColor: "background.primary",
          borderColor: "accent.brightPurple",
          boxShadow: "input.focus",
        },
      },
    }),
    // TODO: Remove this variant in favor of the _invalid selector https://github.com/MaterializeInc/console/issues/1428
    error: defineInputPartsStyle({
      field: {
        ...INPUT_ERROR_STYLES,
      },
    }),
    focused: defineInputPartsStyle({
      field: {
        borderRadius: "lg",
        backgroundColor: "background.primary",
        borderColor: "accent.brightPurple",
        boxShadow: "input.focus",
      },
    }),
  },
});

export const Textarea = defineStyleConfig({
  baseStyle: defineStyle({
    ...Input.baseStyle?.field,
  }),
  variants: {
    default: defineStyle(Input.variants?.default?.field ?? {}),
    error: defineStyle(Input.variants?.error?.field ?? {}),
    focused: defineStyle(Input.variants?.focused?.field ?? {}),
  },
  defaultProps: {
    variant: "default",
    size: "sm",
  },
});
