import { Expression, RawBuilder, Simplify, sql } from "kysely";

import { queryBuilder } from "./db";

export function getOwners() {
  return queryBuilder.selectFrom("mz_roles as r").select((eb) => [
    "r.id",
    "r.name",
    eb
      .or([
        sql<boolean>`(${hasSuperUserPrivileges()})`,
        eb.fn<boolean>("has_role", [
          sql.id("current_user"),
          "r.oid",
          sql.lit("USAGE"),
        ]),
      ])
      .$castTo<boolean>()
      .as("isOwner"),
  ]);
}

export function jsonArrayFrom<O>(
  expr: Expression<O>,
): RawBuilder<Simplify<O>[]> {
  return sql`(select coalesce(jsonb_agg(agg), '[]') from ${expr} as agg)`;
}

// NOTE(benesch): We do not have ideal handling for
// multiprocess clusters (i.e., 2xlarge+ clusters at the time of writing) in
// the Console. Specifically, we use the maximum CPU/memory
// percentage from any process in the replica as each replica's overall
// CPU/memory percentage. Ideally it would return data for each process in
// the replica separately, but the downstream consumers (e.g., the replica
// table) are not yet equipped to handle that.
// A better fix should be handled here (https://github.com/MaterializeInc/console/issues/1041)
export function buildClusterReplicaUtilizationTable(
  {
    mzClusterReplicaUtilization = "mz_cluster_replica_utilization",
  }: {
    mzClusterReplicaUtilization?: "mz_cluster_replica_utilization";
  } = {
    mzClusterReplicaUtilization: "mz_cluster_replica_utilization",
  },
) {
  return queryBuilder
    .selectFrom(`${mzClusterReplicaUtilization} as cru`)
    .groupBy("replica_id")
    .select(({ fn }) => [
      "replica_id",
      fn.max("cru.cpu_percent").as("cpu_percent"),
      fn.max("cru.memory_percent").as("memory_percent"),
      fn.max("cru.disk_percent").as("disk_percent"),
    ]);
}

/**
 * Useful since Kysely's count function returns a union of all number types in TypeScript
 */
export function countAll() {
  return sql<bigint>`count(*)`.as("count");
}

/**
 * This is important for flexible deployment mode where users aren't superusers by default
 * but need superuser privileges. RBAC is disabled by default in flexible deployment mode
 * so we can identify flexible deployment mode by checking the system variable `enable_rbac_checks`
 */
export function hasSuperUserPrivileges() {
  return sql.raw<boolean>(
    `SELECT mz_is_superuser() OR current_setting('enable_rbac_checks') = 'off'`,
  );
}

/**
 *
 * Represents Postgres and MySQL tables for pre and post source versioning. Also represents Kafka and Webhook sources post source versioning.
 */
export function buildSourceDependenciesQuery(sourceId: string) {
  return (
    queryBuilder
      // We use mz_object_dependencies instead of mz_tables and mz_sources since mz_tables is not a retained metrics object yet (https://github.com/MaterializeInc/materialize/pull/30788).
      .selectFrom("mz_object_dependencies as od")
      .leftJoin("mz_sources as subsources", "object_id", "subsources.id")
      .where("referenced_object_id", "=", sourceId)
      .where((eb) =>
        eb.or([
          eb("subsources.type", "<>", "progress"),
          // When the source dependency is a table, subsources.type is null.
          eb("subsources.type", "is", null),
        ]),
      )
      .select(["od.object_id as id", "od.referenced_object_id as sourceId"])
  );
}
