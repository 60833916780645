import { useLatestRef, usePrevious } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import React from "react";

import { MaterializeWebsocket } from "~/api/materialize/MaterializeWebsocket";
import { SubscribeManager } from "~/api/materialize/SubscribeManager";
import { SessionVariables, SqlRequest } from "~/api/materialize/types";
import { currentEnvironmentState } from "~/store/environments";
import { isFocusedState } from "~/store/focus";
import { assert } from "~/util";

/**
 * Connects the socket if on mount once the environment is healthy.
 * Optionally updates request and options if provided.
 * If a subscribe is provided, connect will be called on the subscribe, rather than the
 * socket.
 *
 * Note that changes to sessionVariables do not trigger a reconnect, this reference can be
 * unstable.
 *
 * Also reconnects if all the conditions are true:
 * * the current socket has an error
 * * the environment is healthy
 * * the window is focused
 */
export const useAutomaticallyConnectSocket = <T extends object, R>({
  socket,
  subscribe,
  request,
  sessionVariables,
}: {
  socket: MaterializeWebsocket;
  subscribe?: SubscribeManager<T, R>;
  request?: SqlRequest;
  sessionVariables?: SessionVariables;
}) => {
  const currentEnvironment = useAtomValue(currentEnvironmentState);
  const previousHttpAddress = usePrevious(
    currentEnvironment?.state === "enabled"
      ? currentEnvironment.httpAddress
      : undefined,
  );
  const previousRequest = usePrevious(request);
  const isFocused = useAtomValue(isFocusedState);
  const { error } = React.useSyncExternalStore(
    socket.onChange,
    socket.getSnapshot,
  );
  const sessionVariablesRef = useLatestRef(sessionVariables);

  React.useEffect(() => {
    const environmentHealthy =
      currentEnvironment?.state === "enabled" &&
      currentEnvironment.status.health === "healthy";
    const isErrored = error !== undefined;

    const shouldConnect =
      environmentHealthy &&
      (!socket.isInitialized ||
        (isErrored && isFocused) ||
        previousRequest !== request ||
        currentEnvironment.httpAddress !== previousHttpAddress);
    if (shouldConnect) {
      assert(
        socket.onMessage,
        "onMessage must be set before calling useAutomaticallyConnectSocket",
      );
      if (subscribe) {
        subscribe.connect(
          request,
          currentEnvironment.httpAddress,
          sessionVariablesRef.current,
        );
      } else {
        socket.connect(
          currentEnvironment.httpAddress,
          sessionVariablesRef.current,
        );
      }
    }
  }, [
    sessionVariablesRef,
    currentEnvironment,
    error,
    isFocused,
    previousHttpAddress,
    previousRequest,
    request,
    socket,
    subscribe,
  ]);

  // Make sure the socket disconnects if the component unmounts
  React.useEffect(() => {
    return () => {
      if (subscribe) {
        subscribe.disconnect();
      } else {
        socket.disconnect();
      }
    };
  }, [socket, subscribe]);
};
