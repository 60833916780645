import { QueryKey } from "@tanstack/react-query";
import { InferResult } from "kysely";

import { executeSqlV2, queryBuilder } from "~/api/materialize";
import {
  buildClusterReplicaUtilizationTable,
  getOwners,
} from "~/api/materialize/expressionBuilders";

export function buildClusterReplicasWithUtilizationQuery(clusterId: string) {
  return queryBuilder
    .selectFrom("mz_cluster_replicas as cr")
    .innerJoin(getOwners().as("owners"), "owners.id", "cr.owner_id")
    .innerJoin("mz_clusters as c", "c.id", "cr.cluster_id")
    .innerJoin(
      buildClusterReplicaUtilizationTable().as("cru"),
      "cr.id",
      "cru.replica_id",
    )
    .select([
      "cr.id",
      "cr.name",
      "c.name as clusterName",
      "cr.size",
      "cru.cpu_percent as cpuPercent",
      "cru.memory_percent as memoryPercent",
      "cru.disk_percent as diskPercent",
      "c.managed",
      "owners.isOwner",
    ])
    .where("c.id", "=", clusterId)
    .orderBy("cr.id");
}

export type ClusterReplicaWithUtilizaton = InferResult<
  ReturnType<typeof buildClusterReplicasWithUtilizationQuery>
>[0];

export type ClusterReplicasWithUtilizationParams = {
  clusterId: string;
};

/**
 * Fetches replicas with utilization for a given cluster.
 */
export async function fetchClusterReplicasWithUtilization(
  params: ClusterReplicasWithUtilizationParams,
  queryKey: QueryKey,
  requestOptions?: RequestInit,
) {
  const compiledQuery = buildClusterReplicasWithUtilizationQuery(
    params.clusterId,
  ).compile();
  return executeSqlV2({
    queries: compiledQuery,
    queryKey: queryKey,
    requestOptions,
  });
}
