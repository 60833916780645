import React from "react";

import CheckedMultiSelect from "~/components/Dropdown/CheckedMultiSelect";
import ColumnIcon from "~/svg/ColumnIcon";

import {
  COLUMN_FILTER_ITEMS,
  ColumnItem,
  UseColumnsReturn,
} from "./useColumns";

type ColumnFilterProps = {
  selectedColumnFilterItems: ColumnItem[];
  onColumnChange: UseColumnsReturn["onColumnChange"];
};

const ColumnFilter = ({
  selectedColumnFilterItems,
  onColumnChange,
}: ColumnFilterProps) => {
  return (
    <CheckedMultiSelect
      items={COLUMN_FILTER_ITEMS}
      selectedItems={selectedColumnFilterItems}
      onSelectedItemChange={onColumnChange}
      leftIcon={<ColumnIcon />}
      toggleButtonContent="Columns"
      toggleButtonProps={{
        boxShadow: "none",
      }}
      getItemLabel={(item) => item?.label}
    />
  );
};

export default ColumnFilter;
